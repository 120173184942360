const disableIfNotAdmin = {
  mounted(el, binding) {
    const { value: isAdmin } = binding;

    if (!isAdmin) {
      disableElement(el);
    }
  }
};

function disableElement(el) {
  el.disabled = true;
  el.classList.add('pointer-events-none');

  if (['button', 'fieldset', 'input', 'option', 'select', 'textarea'].includes(el.tagName.toLowerCase())) {
    el.classList.add('opacity-40');
  }

  const childElements = el.children;

  for (let i = 0; i < childElements.length; i++) {
    const child = childElements[i];

    disableElement(child);
  }
}

export default disableIfNotAdmin;
