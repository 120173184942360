<template>
  <notification />
  <router-view
    v-slot="{ Component }"
    @sign-out="onSignOut">
    <transition
      name="fade"
      mode="out-in">
      <component :is="Component" />
    </transition>
  </router-view>
</template>

<script>
export default {
  name: 'App'
};
</script>

<script setup>
import { useStore } from 'vuex';

import Notification from './components/molecules/Notification';

const store = useStore();

const onSignOut = () => {
  store.dispatch('auth/logout');
};
</script>
