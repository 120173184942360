<template>
  <div class="fixed top-28 z-10 flex flex-col left-1/2 transform -translate-x-1/2 wrapper">
    <transition-group
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      enter-active-class="transition ease-out duration-300"
      leave-active-class="transition ease-in duration-300"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
      :duration="{ enter: 300, leave: 300 }">
      <div
        v-for="notification in notifications"
        :key="notification.id"
        :class="containerClass(notification.type)"
        class="alert-container flex items-center w-full max-w-max p-2 rounded-lg shadow mt-4">
        <component
          :is="icon(notification.type)"
          class="alert-icon h-5 w-5"
          aria-hidden="true" />
        <div class="text alert-content">{{ t(notification.message) }}</div>
      </div>
    </transition-group>
  </div>
</template>

<script>
export default {
  name: 'Notification'
};
</script>

<script setup>
import * as heroIcons from '@heroicons/vue/solid';
import { useTranslation } from 'i18next-vue';

import { NotificationService } from '@/services';

const { t } = useTranslation();

const notifications = NotificationService.notifications;

const icon = (type) =>
  ({
    success: heroIcons.CheckCircleIcon,
    info: heroIcons.InformationCircleIcon,
    warning: heroIcons.ExclamationIcon,
    danger: heroIcons.XCircleIcon
  })[type];

const containerClass = (type) =>
  ({
    success: 'alert-success',
    info: 'alert-info',
    warning: 'alert-warning',
    danger: 'alert-danger'
  })[type];
</script>

<style lang="scss" scoped>
.wrapper {
  transform: translate(-50%);
}

.alert-success {
  &.alert-container {
    @apply bg-green-100;
  }

  .alert-icon {
    @apply text-green-400;
  }

  .alert-content {
    @apply text-green-800;
  }

  .alert-close {
    @apply bg-green-50 text-green-500 hover:bg-green-100 focus:ring-offset-green-50 focus:ring-green-600;
  }
}

.alert-info {
  &.alert-container {
    @apply bg-blue-50;
  }

  .alert-icon {
    @apply text-blue-400;
  }

  .alert-content {
    @apply text-blue-800;
  }

  .alert-close {
    @apply bg-blue-50 text-blue-500 hover:bg-blue-100 focus:ring-offset-blue-50 focus:ring-blue-600;
  }
}

.alert-warning {
  &.alert-container {
    @apply bg-yellow-50;
  }

  .alert-icon {
    @apply text-yellow-400;
  }

  .alert-content {
    @apply text-yellow-800;
  }

  .alert-close {
    @apply bg-yellow-50 text-yellow-500 hover:bg-yellow-100 focus:ring-offset-yellow-50 focus:ring-yellow-600;
  }
}

.alert-danger {
  &.alert-container {
    @apply bg-red-50;
  }

  .alert-icon {
    @apply text-red-400;
  }

  .alert-content {
    @apply text-red-800;
  }

  .alert-close {
    @apply bg-red-50 text-red-500 hover:bg-red-100 focus:ring-offset-red-50 focus:ring-red-600;
  }
}

.notification {
  display: inline-flex;
  padding: var(--space-100, 8px) var(--space-200, 16px);
  align-items: center;
  gap: var(--space-100, 8px);

  border-radius: 4px;
  background: var(--color-success-50, #e5f8ec);

  box-shadow:
    0 8px 16px 0 rgba(0, 51, 80, 0.16),
    0 2px 4px 0 rgba(0, 51, 80, 0.04);
}

.text {
  overflow: hidden;
  color: var(--color-neutrals-800, #2d3748);
  font-kerning: none;
  text-overflow: ellipsis;
  font-family: Inter, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  letter-spacing: -0.42px;
}
</style>
