import '../src/tailwind.css';

import { createApp } from 'vue';
import { VuePdf } from 'vue3-pdfjs';

import { setValidationRules } from '@/setValidationRules';

import App from './App.vue';
import config from './config';
import disableIfNotAdmin from './directives';
import i18n from './i18n';
import interceptorsSetup from './interceptors';
import router from './router';
import store from './store';

const initApp = async () => {
  await config.load();

  interceptorsSetup(store);
  setValidationRules();

  const app = createApp(App);

  app.use(VuePdf);
  i18n(app);

  await store.dispatch('auth/preInit');
  await store.dispatch('auth/init');

  app.use(router);
  app.use(store);
  app.directive('disableIfNotAdmin', disableIfNotAdmin);

  app.mount('#app');
};

initApp();
