import axios from 'axios';

import { name } from '../package.json';

export default function setup(store) {
  axios.interceptors.request.use(
    (config) => {
      const token = store.getters['auth/token'];

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      return config;
    },
    (err) => Promise.reject(err)
  );

  axios.interceptors.request.use(
    (config) => {
      const key = store.getters['auth/deliveryhubAccountKey'];

      if (key) {
        config.headers.zkkey = key;
      }

      return config;
    },
    (err) => Promise.reject(err)
  );

  axios.interceptors.request.use(
    (config) => {
      config.headers['client-app'] = name;

      return config;
    },
    (err) => Promise.reject(err)
  );

  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      const { config } = error || {};
      const { status } = error.response || {};

      if (status === 401 && !config.ignoreLogout) {
        await store.dispatch('auth/logout');
      }

      return Promise.reject(error);
    }
  );
}
